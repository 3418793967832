import React, { useEffect, useState } from "react";
import '../components/Firebase';
import firebase from 'firebase/app';
import 'firebase/database';
import { useParams, useSearchParams } from "react-router-dom";

export default function Direct() {
    const routeParams = useParams();
    const [searchParams] = useSearchParams();
    const param1 = searchParams.get('q');
    const [url, setURL] = useState('');

    const fetchUserData = () => {
        firebase.database()
            .ref('users/card_details/' + routeParams.id) 
            .once('value')
            .then((snapshot) => {
                const userData = snapshot.val();
                if(userData.link.includes('activeren')){
                    window.location.replace(userData.link)
                }
                else{
                    window.location.replace(userData.link + `?q=${param1}`)
                }
                
                // if (userData.link.includes("popitnl.nl")){
                //     window.location.replace(userData.link)
                // }else{
                //     setURL(userData.link + `?q=${param1}`);
                // }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    return (
        // url ? (
        //     <div style={{ width: '100vw', height: '100vh', margin: 0, padding: 0, overflow: 'hidden' }}>
        //     <iframe
        //         src={url}
        //         width="100%"
        //         height="100%"
        //         style={{ border: 'none' }}
        //         title="Embedded Website"
        //     ></iframe>
        // </div>
        // ) : null
        <div></div>
        
    );
}
